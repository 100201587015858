import {ref} from "@vue/composition-api"
import {
    fetchAdvertisementType,
    fetchAdvertisersList,
    fetchCampaignsList,
    fetchBrowsers, fetchBrowserVersionsList,
    fetchDeviceList,
    fetchGeoList,
    fetchOSList, fetchOSVersionsList,
    fetchPlacementsList,
    fetchPlatformsList, fetchPresetsList,
    fetchPublishersList, fetchRegionsList
} from "@/api/lists"
import apiInstance from '@/libs/axios'
import {uniq} from "underscore"

export default function() {

    const publishersList = ref([])
    const platformsList = ref([])
    const campaignsList = ref([])
    const placementsList = ref([])
    const advertisementTypesList = ref([])
    const advertisersList = ref([])
    const devicesList = ref([])
    const geoList = ref([])
    const geoWhiteList = ref([])
    const geoBlackList = ref([])
    const osList = ref([])
    const browsersList = ref([])
    const browsersVersionsList = ref([])
    const osVersionsList = ref([])
    const regionsList = ref([])
    const presetsList = ref([])
    const currentDateString = ref(null)
    const todayDate = ref(null)
    const dateRange = ref({
        startDate: new Date(),
        endDate: new Date()
    })

    const publishersListNextPage = ref(null)
    const campaignsListNextPage = ref(null)
    const platformsListNextPage = ref(null)
    const placementsListNextPage = ref(null)
    const advertisersListNextPage = ref(null)
    const geoListNextPage = ref(null)
    const geoWhiteListNextPage = ref(null)
    const geoBlackListNextPage = ref(null)
    const osListNextPage = ref(null)
    const browsersListNextPage = ref(null)
    const browsersVersionsListNextPage = ref(null)
    const osVersionsListNextPage = ref(null)
    const regionsListNextPage = ref(null)
    const presetsListNextPage = ref(null)

    const publishersInitialList = ref([])
    const campaignsInitialList = ref([])
    const platformsInitialList = ref([])
    const placementsInitialList = ref([])
    const advertisersInitialList = ref([])
    const geoInitialList = ref([])
    const geoInitialWhiteList = ref([])
    const geoInitialBlackList = ref([])
    const osInitialList = ref([])
    const browsersInitialList = ref([])
    const browsersVersionsInitialList = ref([])
    const osVersionsInitialList = ref([])
    const regionsInitialList = ref([])
    const presetsInitialList = ref([])

    const namedLists = {
        publishers: [publishersList, publishersListNextPage, publishersInitialList],
        campaigns: [campaignsList, campaignsListNextPage, campaignsInitialList],
        platforms: [platformsList, platformsListNextPage, platformsInitialList],
        placements: [placementsList, placementsListNextPage, placementsInitialList],
        advertisement_types: [advertisementTypesList],
        dsps: [advertisersList, advertisersListNextPage, advertisersInitialList],
        device_types: [devicesList],
        geos: [geoList, geoListNextPage, geoInitialList],
        whitelist_geo: [geoWhiteList, geoWhiteListNextPage, geoInitialWhiteList],
        blacklist_geo: [geoBlackList, geoBlackListNextPage, geoInitialBlackList],
        oses: [osList, osListNextPage, osInitialList],
        browsers: [browsersList, browsersListNextPage, browsersInitialList],
        browser_versions: [browsersVersionsList, browsersVersionsListNextPage, browsersVersionsInitialList],
        os_versions: [osVersionsList, osVersionsListNextPage, osVersionsInitialList],
        regions: [regionsList, regionsListNextPage, regionsInitialList],
        presets: [presetsList, presetsListNextPage, presetsInitialList]
    }

    const availableLists = {
        publishers: fetchPublishersList,
        campaigns: fetchCampaignsList,
        platforms: fetchPlatformsList,
        placements: fetchPlacementsList,
        advertisement_types: fetchAdvertisementType,
        dsps: fetchAdvertisersList,
        device_types: fetchDeviceList,
        geos: fetchGeoList,
        whitelist_geo: fetchGeoList,
        blacklist_geo: fetchGeoList,
        oses: fetchOSList,
        browsers: fetchBrowsers,
        browser_versions: fetchBrowserVersionsList,
        os_versions: fetchOSVersionsList,
        regions: fetchRegionsList,
        presets: fetchPresetsList
    }

    let debounce = null

    const handleListsSearch = (event) => {
        if (event[1].length > 1 || (event[1].length === 0 && !event[2]?.length)) {
            clearTimeout(debounce)
            debounce = setTimeout(() => {
                listsSearch(event[0], event[1])
                    .then((res) => {
                        namedLists[event[0]][0].value = res.data.data
                        namedLists[event[0]][1].value = res.data.links?.next
                        namedLists[event[0]][2].value = uniq([...namedLists[event[0]][2].value, ...res.data.data], (item) => item.id)
                    })
                    .catch(() => {

                    })
            }, 600)
        } else if (event[1].length === 0 && event[2]?.length) {
            clearTimeout(debounce)
            debounce = setTimeout(() => {
                listsSearch(event[0], event[1], event[2])
                    .then((res) => {
                        namedLists[event[0]][0].value = res.data.data
                        namedLists[event[0]][1].value = res.data.links?.next
                        namedLists[event[0]][2].value = uniq([...namedLists[event[0]][2].value, ...res.data.data], (item) => item.id)
                    })
                    .catch(() => {

                    })
            }, 600)
        }
    }

    const listsSearch = (list, query, picked) => {
        if (!(query === '' || query.length >= 2)) return
        if (!availableLists[list]) return
        let queryFlag = list
        if (['blacklist_geo', 'whitelist_geo'].includes(list)) queryFlag = 'geos'
        if (query === '' && picked?.length) return availableLists[list](`${queryFlag}=${picked}`, 1)
        return availableLists[list](`nameScope=${query}`, 1)
    }

    const handleLoadLists = (names, isLoading) => {
        let namesLength = names.length
        names.forEach((name) => {
            let list
            let query
            if (Array.isArray(name)) {
                list = name[0]
                query = name[1]
            } else {
                list = name
                query = null
            }
            if (!availableLists[list]) return
            availableLists[list](query || '', 1)
                .then((res) => {
                    namedLists[list][0].value = res.data.data
                    if (res.data.links?.next) namedLists[list][1].value = res.data.links?.next
                    namedLists[list][2].value = res.data.data
                    namesLength--
                    if (namesLength <= 1 && isLoading) isLoading.value = false
                })
        })
    }

    const handlePaginateList = (name) => {
        if (!namedLists[name][1].value) return

        apiInstance.get(namedLists[name][1].value)
            .then((res) => {
                namedLists[name][0].value.push(...res.data.data)
                namedLists[name][1].value = res.data.links?.next
                namedLists[name][2].value = uniq([...namedLists[name][2].value, ...res.data.data], (item) => item.id)
            })
    }

    return {
        publishersList,
        platformsList,
        placementsList,
        advertisementTypesList,
        advertisersList,
        campaignsList,
        devicesList,
        geoList,
        geoWhiteList,
        geoBlackList,
        osList,
        browsersList,
        browsersVersionsList,
        osVersionsList,
        regionsList,
        currentDateString,
        todayDate,
        dateRange,
        namedLists,
        presetsList,
        geoListNextPage,
        handleListsSearch,
        handleLoadLists,
        handlePaginateList
    }
}
