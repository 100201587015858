<template>
  <div>
    <b-row class="d-flex align-items-end">
    <b-col sm="6">
      <label for="url">VAST URL</label>
    <BFormInput name="url" v-model="tag" @input="handleInput" label="VAST URL" class="mb-2" placeholder="https://example.com"/>
  <MultiselectCheckboxes
      ref="placementSelect"
      class="flex-1-1-0 p-0"
      is-small
      :on-change="pickPlacement"
      name="placements"
      :facets="placementsList"
      v-model="pickedPlacement"
      :state="pickedPlacement"
      track-by="id"
      is-paginated
      single
      label="Размещения"
      :lists="namedLists"
      @paginate="handlePaginateList"
      @search="handleListsSearch"/>
    </b-col>
    <b-col>
      <MultiselectCheckboxes
          ref="campaignSelect"
          class="flex-1-1-0 p-0"
          is-small
          :on-change="pickCampaign"
          name="campaigns"
          :facets="campaignsList"
          v-model="pickedCampaign"
          :state="pickedCampaign"
          track-by="tag"
          is-paginated
          single
          label="РК"
          :lists="namedLists"
          @paginate="handlePaginateList"
          @search="handleListsSearch"/>
    </b-col>
    </b-row>
    <b-button variant="primary" class="my-2" @click="loadPlayer">Загрузить</b-button>
    <div class="player-wrap d-md-flex">
    <div class="player" ref="player"></div>
      <div class="player-logs">
        <p v-for="log in logs">{{log}}</p>
        <BButton v-if="logs.length" class="player-clear-logs" variant="primary" @click="clearLogs">Очистить</BButton>
      </div>
    </div>
  </div>
</template>
<script>
import {ref} from "@vue/composition-api"
import statisticLists from "@/use/statisticLists"
import MultiselectCheckboxes from "@/components/MultiselectCheckboxes"
import formsLists from "@/use/formsLists"

export default {
  name: 'Player',
  components: {MultiselectCheckboxes},
  setup() {
    const {
      placementsList,
      campaignsList,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList,
      namedLists
    } = formsLists()

    const campaignSelect = ref(null);
    const placementSelect = ref(null);

    const logs = ref([]);

    const tag = ref('');
    const willFetchTag = ref(false);

    const pickedPlacement = ref(null)
    const pickedCampaign = ref(null);

    const pickPlacement = (val) => {
      pickedPlacement.value = val;
      willFetchTag.value = false;
      pickedCampaign.value = [];
      tag.value = '';
      campaignSelect.value.deselectAll();
    }

    const handleInput = () => {
      willFetchTag.value = true;
      campaignSelect.value.deselectAll();
      placementSelect.value.deselectAll();
      pickedPlacement.value = [];
      pickedCampaign.value = [];
    }

    const clearLogs = () => {
      logs.value = [];
    }

    const pickCampaign = (val) => {
      willFetchTag.value = true;
      pickedCampaign.value = val;
      tag.value = val.at(-1);
      pickedPlacement.value = [];
      placementSelect.value.deselectAll();
    }

    handleLoadLists(['placements', 'campaigns'])

    window.addEventListener('message', (e) => {
      if(!(e.origin === 'https://player.sputnik24.tv')) return;
      const msg = JSON.parse(e.data);
      if (!msg.s24player) return;
      logs.value.push(`${new Date().toLocaleString()} ${msg.type}`);
    })

    return {
      placementsList,
      campaignSelect,
      placementSelect,
      campaignsList,
      pickCampaign,
      clearLogs,
      pickedCampaign,
      tag,
      logs,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList,
      namedLists,
      pickedPlacement,
      pickPlacement,
      willFetchTag,
      handleInput
    }
  },
  created() {

  },
  mounted() {
    var scripts = [
      "https://prevideo2.sputnik24.tv/embed/embed.js",
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  methods: {
    loadPlayer() {
      this.logs = [];
      this.$nextTick(() => {
        const player = document.querySelector('.player');
        player.innerHTML = '';
        if(this.willFetchTag) {
          window.sputnikInit({
            type: 'affiliate',
            targetElement: player,
            vastUrl: this.tag,
            logging: true,
          })
        } else {
          window.sputnikInit({
            type: 'affiliate',
            targetElement: player,
            id: this.pickedPlacement.at(-1),
            logging: true,
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.player {
  height: 360px;
  width: 100%;

  &-wrap {
    gap: 20px;
  }

  &-logs {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-clear-logs {
    margin-top: auto;
    width: 128px;
  }
}
</style>
